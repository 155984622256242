.home-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  flex-direction: column;
  gap: 50px;
}
.text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  height: 80vh;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.dd{
  height: 200vh;
  background-color: #f9f9f9;
  width: 90vh;
 
 


}


.text-container h4 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
  text-align: center;
}

.responsive-textarea {
  width: 100%;
  max-width: 600px;
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  resize: none;
  background-color: #fff;
}

.responsive-textarea:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.5);
}

.mainBtn {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  width: fit-content;
}

.mainBtn:hover {
  background-color: #0056b3;
}

.mainBtn {
  background-color: #003366;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  width: max-content;
  height: max-content;
  display: flex;
  align-items: center;
  gap: 3px;
}

.mainBtn:hover {
  background-color: rgba(57, 127, 194, 1);
  transition: background-color 0.3s;
}
@media (max-width: 768px) {
  .hero-container h4 {
    font-size: 1.2rem;
  }

  .responsive-textarea {
    font-size: 14px;
    padding: 10px;
  }

  .mainBtn {
    padding: 10px 18px;
    font-size: 0.9rem;
  }
}

@media (max-width: 480px) {
  .hero-container h4 {
    font-size: 1rem;
  }

  .responsive-textarea {
    font-size: 12px;
    padding: 8px;
  }

  .mainBtn {
    padding: 8px 15px;
    font-size: 0.8rem;
  }
}
