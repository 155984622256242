.bigContainer{
  display: flex;
  flex-direction: column;
  gap:30px;
  justify-content: center;
  align-items: center;
}

.awards-container {
  width: 100%;
  margin: auto;
  padding-bottom: 20px;
  min-height: 100vh;
  box-sizing: border-box;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}
input{
  width: 73%;
}
form{
  margin: 20px;
  justify-content: center;
 flex-direction: row;
 align-items: center;
 
}
.create-btn {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-btn:hover {
  background-color: #45a049;
}

.awards-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.award-card {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 22%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.award-card h3 input,
.award-card p input,
.award-card p textarea {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.award-card textarea {
  height: 100px;
}

.delete-btn {
  display: block;
  margin: 10px 0;
  padding: 8px 15px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #d32f2f;
}

@media (max-width: 1200px) {
  .award-card {
    width: 30%;
  }
}

@media (max-width: 900px) {
  .award-card {
    width: 45%;
  }
}

@media (max-width: 600px) {
  .award-card {
    width: 100%;
  }

  h2 {
    font-size: 1.5rem;
  }

  .create-btn {
    padding: 8px 16px;
  }

  .award-card {
    padding: 15px;
  }

  .delete-btn {
    padding: 6px 12px;
  }

  .award-card h3 input,
  .award-card p input,
  .award-card p textarea {
    font-size: 0.9rem;
  }
}
