.work-experience-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: 35%;
    margin: 20px auto;
  }
  
  .work-experience-container h3 {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
  }
  
  .experience-entry {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    width: 100%;
  }
  
  .experience-entry label {
    font-size: 1rem;
    margin-bottom: 8px;
    color: #555;
  }
  
  .experience-entry input, 
  .experience-entry textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  .experience-entry textarea {
    resize: vertical;
    min-height: 100px;
  }
  
  .submit-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 12px 20px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
    width: 200px;
    margin-top: 20px;
  }
  
  .submit-btn:hover {
    background-color: #0056b3;
  }
  
  @media (max-width: 768px) {
    .work-experience-container h3 {
      font-size: 1.3rem;
    }
  
    .experience-entry input, 
    .experience-entry textarea {
      font-size: 0.9rem;
    }
  
    .submit-btn {
      padding: 10px 16px;
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 480px) {
    .work-experience-container h3 {
      font-size: 1.1rem;
    }
  
    .experience-entry input, 
    .experience-entry textarea {
      font-size: 0.85rem;
      padding: 8px;
    }
  
    .submit-btn {
      padding: 8px 14px;
      font-size: 0.8rem;
    }
  }
  