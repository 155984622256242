.research-container {
  padding: 20px;
  /* max-width: 900px; */
  margin: 0 auto;
  background-color: #f4f4f4;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}
form{
  width: 50%;
}

.create-btn {
  display: block;
  margin: 10px auto;
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.create-btn:hover {
  background-color: #0056b3;
}

.research-list {
  display: flex;
  /* flex-direction: column; */
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.research-card {
  background: white;
  padding: 15px;
  border-radius: 5px;
  width: 30%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  position: relative;
}

.research-card-counter {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 15px;
  top: -10px;
  left: -10px;
  color: white;
  display: grid;
  place-items: center;
  font-size: 20px;
  background-color: #007bff;
}

input,
textarea,
select {
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

a {
  color: #007bff;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

.delete-btn {
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #dc3545;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.delete-btn:hover {
  background-color: #c82333;
}
