.image-upload-container {
  margin-bottom: 20px;
  text-align: center;
}
.image-list{
  display: flex;
  gap:20px

}
.upload-form {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.image-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.image-card {
  position: relative;
  width: 150px;
  height: 150px;
}

.uploaded-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.delete-btn1-unique {
  position: absolute;
  top: 5px;
  right: 5px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.delete-btn1-unique:hover {
  transform: scale(1.1);
  background-color: #ff4d4d;
}

.academic-container {
  width: 100%;
  margin: auto;
  padding: 20px;
  min-height: 100vh;
  box-sizing: border-box;
}
.academic-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.academic-card {
  background-color: #f9f9f9;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 22%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.academic-card h3 input,
.academic-card p input,
.academic-card p textarea {
  width: 100%;
  padding: 8px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
}

.academic-card textarea {
  height: 100px;
}
