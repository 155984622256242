.industrial-experience-container {
    padding: 20px;
    width:80% ;
    margin: 0 auto;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   
  }
  h3 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.8rem;
  }
  
  .formExp {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width:50% ;
    align-items: center;
    justify-content: center;
  }
  
  .experience-entry {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  label {
    font-size: 1rem;
    font-weight: bold;
  }
  
  input,
  textarea {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  textarea {
    resize: none;
    height: 80px;
  }
  
 