input,
textarea {
  height: fit-content;
}

::-webkit-scrollbar {
  width: 7px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #9c9e9e;
  border-radius: 20px;
  /* border: 6px solid transparent; */
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #595a5a;
}
